<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      v-model="params"
      @search="search"
      @reset="reset"
      show-search
      v-if="searchFields.length"
      :loading="loading"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #period="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
                >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
        <template #typeId="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
                >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(amount)="data">
            <span class="text-meron">
              {{ data.item.amount | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(imageUrl)="data">
            <a
              :href="data.item.imageUrl"
              target="_blank"
              v-if="data.item.imageUrl"
            >
              <b-img
                :src="data.item.imageUrl"
                fluid
                style="height: 50px"
              ></b-img>
            </a>
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
          <template #cell(status)="data">
            <span
              v-if="data.item.status == 1"
              class="badge badge-pill badge-success"
              >{{ $t("status.approved") }}</span
            >
            <span
              v-else-if="data.item.status == 2"
              class="badge badge-pill badge-danger"
              >{{ $t("status.rejected") }}</span
            >
            <span v-else class="badge badge-pill badge-warning">{{
              $t("status.pending")
            }}</span>
          </template>
          <template #cell(actions)="data">
            <b-button
              v-if="data.item.status == 0 && $can('update', 'request-deposit')"
              v-b-tooltip.hover
              @click="approveConfirm(data.item)"
              :title="$t('button.approve')"
              variant="success"
              pill
              size="sm"
            >
              <feather-icon icon="CheckIcon" />
            </b-button>
            <b-button
              v-if="data.item.status == 0 && $can('update', 'request-deposit')"
              v-b-tooltip.hover
              @click="rejectConfirm(data.item)"
              :title="$t('button.reject')"
              variant="danger"
              pill
              size="sm"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </template>
          <template #custom-foot>
            <b-tr v-if="!loading && totalReport.total">
              <b-th colspan="3"></b-th>
              <b-th class="text-right">
                <h5 class="text-meron font-weight-bold">
                  {{ totalReport.total | currency(totalReport.ccy) }}
                </h5>
              </b-th>
              <b-th colspan="1"></b-th>
              <b-th colspan="1"></b-th>
              <b-th colspan="1"></b-th>
              <b-th colspan="1"></b-th>
              <b-th colspan="1"></b-th>
              <b-th colspan="1"></b-th>
              <b-th colspan="1"></b-th>
            </b-tr>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
          ref="pagination"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BImg,
  BTr,
  BTh,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import tableFields from "./tableField";
import searchInputs from "./searchInput";
import { avatarText } from "@core/utils/filter";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import moment from "moment";

const WithdrawRequestRepository = Repository.get("withdrawRequest");
const DepositRequestRepository = Repository.get("depositRequest");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BImg,
    BTr,
    BTh,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.endDate = dates[1];
      }
    },
    typeId: function () {
      this.setSearchFields();
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    activeSearch() {
      return (
        !!this.$route.query.userId ||
        !!this.$route.query.startDate ||
        !!this.$route.query.endDate
      );
    },
    typeId: function () {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId;
      }
      return this.userData?.id;
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
        userId: Number(this.$route.query.userId) || null,
        startDate: this.$route.query.startDate || moment().format("YYYY-MM-DD"),
        endDate: this.$route.query.endDate || moment().format("YYYY-MM-DD"),
        period: this.$route.query.period || "today",
        search: this.$route.query.search || null,
      },
      avatarText,
      searchFields: [],
      totalReport: {},
    };
  },
  mounted() {
    this.getData(this.params.page);
    if (this.$store.state.profile.typeId) {
      this.setSearchFields();
    }
  },
  methods: {
    setSearchFields() {
      this.searchFields = [...searchInputs];
    },
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search(searchText) {
      this.params.search = searchText;
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.params.page = 1;
      this.$refs.pagination.reset();
      this.params.startDate = moment().format("YYYY-MM-DD");
      this.params.toDate = moment().format("YYYY-MM-DD");
      this.params.period = "today";
      this.params.search = "";
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      WithdrawRequestRepository.index({
        ...this.params,
      })
        .then((response) => {
          let data = response.data.data.list;
          this.items = [...data];
          this.total = response.data.data.total;
          this.loading = false;
          this.getTotal();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getTotal() {
      WithdrawRequestRepository.total({
        ...this.params,
      })
        .then((response) => {
          let data = response.data.data;
          this.totalReport = {
            ...data,
          };
        })
        .catch(() => {});
    },
    approveConfirm(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
          title: this.$t("alert.areYouSure"),
          okVariant: "primary",
          size: "sm",
          buttonSize: "md",
          okTitle: this.$t("button.approve"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          okVariant: "success",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.approveReject(item, 1);
          }
        });
    },
    rejectConfirm(item) {
      this.$bvModal
        .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
          title: this.$t("alert.areYouSure"),
          okVariant: "primary",
          size: "sm",
          buttonSize: "md",
          okTitle: this.$t("button.reject"),
          cancelTitle: this.$t("button.cancel"),
          cancelVariant: "outline-secondary",
          okVariant: "danger",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.approveReject(item, 2);
          }
        });
    },
    approveReject(item, status) {
      // Status 1: Approve; 2: Reject
      const data = {
        trxId: item.id,
        status: status,
      };
      DepositRequestRepository.update(data)
        .then((response) => {
          this.getData();
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...tableFields];
    return {
      fields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
